<template>
  <v-row no-gutters>
    <v-col cols="3" md="3" xl="3">
      <issues-sidebar></issues-sidebar>
    </v-col>
    <v-col>
      <!-- <issues-sidebar-filters></issues-sidebar-filters> -->
      <issues-map></issues-map>
    </v-col>
    <v-col cols="3" md="3" xl="2" v-if="getIssuesIssue.id">
      <issue-sidebar></issue-sidebar>
    </v-col>
    <div style="position: absolute; bottom: 0px; width: 70%; margin-left: 26%; z-index: 4;">
      <div style="display: flex; justify-content: space-evenly;">
        <v-card
            style="height: 115px; bottom: 50px;"
            color="info"
            @click="setSelectedResult('all')">
            <div class="ml-2 d-flex flex-no-wrap justify-space-between">
              <div>
                <!-- <v-card-subtitle class="pb-0 pt-2">Fecha: 02/02/2020</v-card-subtitle> -->
                <v-card-title class="headline pb-0 pt-2" style="color: #F68628; font-weight: 900;">Encuestados</v-card-title>
                <v-card-actions class="mt-0 pb-0 pt-2">
                </v-card-actions>
              </div>
              <div
                style="width: 85px;
                  height: 85px;
                  text-align: center;
                  margin-top: 18px;
                  padding-top: 26px;
                  margin-left: 20px;
                  margin-right: 20px;
                  border-radius: 50%;
                  background-color: #F68628;
                  color: #fff;
                  font-weight: 900;
                  font-size: 20px;">
                {{ getIssuesIssues.length }}
              </div>
            </div>
        </v-card>
        <v-card
            style="height: 115px; bottom: 50px;"
            color="info"
            @click="setSelectedResult('all')">
            <div class="ml-2 d-flex flex-no-wrap justify-space-between">
              <div>
                <!-- <v-card-subtitle class="pb-0 pt-2">Fecha: {{Date.now()}}</v-card-subtitle> -->
                <v-card-title class="headline pb-0 pt-2" style="color: #F68628; font-weight: 900;">Encuestadores</v-card-title>
                <v-card-actions class="mt-0 pb-0 pt-2">
                </v-card-actions>
              </div>
              <div
                style="width: 85px;
                  height: 85px;
                  text-align: center;
                  margin-top: 18px;
                  padding-top: 26px;
                  margin-left: 20px;
                  margin-right: 20px;
                  border-radius: 50%;
                  background-color: #F68628;
                  color: #fff;
                  font-weight: 900;
                  font-size: 20px;">
                {{ usersResults.length }}
              </div>
            </div>
        </v-card>
      </div>
    </div>
  </v-row>
</template>
<script>
import IssueSidebar from '@/components/app/issues/issue-sidebar'
import IssuesMap from '@/components/app/issues/issues-map-gmap'
import IssuesSidebar from '@/components/app/issues/issues-sidebar'
// import IssuesSidebarFilters from '@/components/app/issues/issues-sidebar-filters'
// import AppConfig from '@/constants/app-config'
import { to } from '@/helpers'
import { ISSUES_GET_ISSUES, SETTINGS_HIDE_SIDEBAR, GROUPS_GET_GROUPS, USERS_GET_USERS } from '@/store/actions.type'
import { ISSUES_SET_SELECTED_ISSUE_RESULT } from '@/store/mutations.type'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Issues',
  props: {},
  data() {
    return {
      isLoading: true,
      error: null
    }
  },
  computed: {
    ...mapGetters(['getIssuesIssues', 'getUsers', 'getIssuesIssue', 'getAllGroups', 'getselectedGroupds']),
    usersResults() {
      const groups = this.getselectedGroupds
      let usersResults = []
      usersResults = this.getUsers.filter(user => groups.length === 0 ? user : user.groups.some(gp => groups.includes(gp.id)))
      return usersResults
    }
  },
  methods: {
    ...mapActions({
      hideSideBar: SETTINGS_HIDE_SIDEBAR,
      getUsersApi: USERS_GET_USERS,
      getGroups: GROUPS_GET_GROUPS
    }),
    setSelectedResult(testResult) {
      this.$store.commit(ISSUES_SET_SELECTED_ISSUE_RESULT, testResult)
      this.getIssues()
    },
    async getIssues() {
      this.isLoading = true
      const [err] = await to(
        this.$store.dispatch(ISSUES_GET_ISSUES)
      )
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.hideSideBar()
    this.getUsersApi()
    this.getGroups()
  },
  components: {
    IssuesMap,
    IssuesSidebar,
    IssueSidebar
    // IssuesSidebarFilters
  }
}
</script>
